import React from 'react';
import styled from 'styled-components';

const BannerStyle = styled.div`
  align-items: center;
  background-color: #0094ff;
  display: flex;
  height: 60px;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const Banner = () => {
  return (
    <BannerStyle>
      <a
        href="https://taxfoundation.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg height="35" width="228" viewBox="0 0 228 35">
          <polygon
            fill="#ffffff"
            points="42.5,26 45.5,26 45.5,12 50.5,12 50.5,9 37.5,9 37.5,12 42.5,12 	"
          />
          <path
            fill="#ffffff"
            d="M56.736,9H53.5l-6.422,17H50l1.5-4h7l1.5,4h3.5l-0.336-0.28L56.736,9z M52.5,19l2.598-7.053h0.047h0.024L57.709,19H52.5z"
          />
          <polygon
            fill="#ffffff"
            points="78,9 74.782,9 63.5,26 63.5,26 66.738,26 	"
          />
          <polygon fill="#ffffff" points="67,9 63.5,9 75,26 78.5,26 	" />
          <polygon
            fill="#ffffff"
            points="88.5,26 91.5,26 91.5,19.001 98.5,19.001 98.5,16 91.5,16 91.5,12 100.5,12 100.5,9 88.5,9 	"
          />
          <path
            fill="#ffffff"
            d="M108.5,8.5c-5,0-8,4.5-8,9s3,9,8,9s8-4.5,8-9S113.5,8.5,108.5,8.5z M108.5,24c-4,0-5-4-5-6.5s1-6.5,5-6.5s5,4,5,6.5S112.5,24,108.5,24z"
          />
          <path
            fill="#ffffff"
            d="M128.5,18.898c0,2.102,0,5.102-4,5.102s-4-3-4-5.102V9h-3v10.872c0,4.128,3,6.628,7,6.628s7-2.5,7-6.628V9h-3V18.898z"
          />
          <polygon
            fill="#ffffff"
            points="144.5,21.512 136.5,9 133.5,9 133.5,26 136.5,26 136.5,13.509 144.5,26 147.5,26 147.5,9 144.5,9 	"
          />
          <path
            fill="#ffffff"
            d="M156.5,9h-7v17h7c5,0,7-4,7-8.503C163.5,13,161.5,9,156.5,9z M155,23.549h-2.5v-12.1h2.5c4.641,0,5.5,2.551,5.5,6.049C160.5,21,159.641,23.549,155,23.549z"
          />
          <rect x="191.5" y="9" fill="#ffffff" width="3" height="17" />
          <path
            fill="#ffffff"
            d="M17.5,1C8.388,1,1,8.387,1,17.5c0,3.438,1.052,6.63,2.852,9.271L4.5,25c0,0,4-2,6-2c1,0,2,0,4,1c-1-2-3-2-4-2c-3,0-6,2-6,2l-1-1c0-0.5,0.5-0.875,1-1c0,0,3-2,5-2c1,0,3,0,4,1c-1-2-3-2-4-2c-2,0-5,2-5,2c0-1,1-2,1-2c0-2,4-5,4-5v-2l1-3l1,3v2c0,0,4,3,4,5c0,0,1,1,1,2c0,0,0,1-1,1c0,0,2,0,2,1l-1,1v1l13.158,3.65C32.355,25.714,34,21.8,34,17.5C34,8.387,26.613,1,17.5,1z"
          />
          <polygon
            fill="#ffffff"
            points="12.5,26 10.5,27 8.5,26 5.5,28 4.5,28 5.5,29 8.5,27 8.5,31 9.5,32 9.5,27.5 10.5,28 11.5,27.5 11.5,32 12.5,33 12.5,27 14.5,27.5 14.5,33 15.5,33 15.5,27.75 17.5,28.25 17.5,33 18.5,33 18.5,28.5 20.5,29 20.5,33 21.5,33 21.5,29.25 23.5,29.75 23.5,33 24.5,32 24.5,30 28.5,31 28.5,30 	"
          />
          <path
            fill="#ffffff"
            d="M172.5,9h-3L163,26h3l1.5-4h7l1.5,4h3L172.5,9z M168.5,19l2.5-7l2.5,7H168.5z"
          />
          <polygon
            fill="#ffffff"
            points="181.5,26 184.5,26 184.5,12 189.5,12 189.5,9 176.5,9 176.5,12 181.5,12 	"
          />
          <polygon
            fill="#ffffff"
            points="223.5,21.512 215.5,9 212.5,9 212.5,26 215.5,26 215.5,13.509 223.5,26 226.5,26 226.5,9 223.5,9 	"
          />
          <path
            fill="#ffffff"
            d="M203.5,8.5c-5,0-8,4.5-8,9s3,9,8,9s8-4.5,8-9S208.5,8.5,203.5,8.5z M203.5,24c-4,0-5-4-5-6.5s1-6.5,5-6.5s5,4,5,6.5S207.5,24,203.5,24z"
          />
        </svg>
      </a>
    </BannerStyle>
  );
};

export default Banner;
