export default [
  {
    id: 0,
    value: '$0 to $10k',
  },
  {
    id: 1,
    value: '$10 to $25k',
  },
  {
    id: 2,
    value: '$25k to $50k',
  },
  {
    id: 3,
    value: '$50k to $75k',
  },
  {
    id: 4,
    value: '$75k to $100k',
  },
  {
    id: 5,
    value: '$100k to $200k',
  },
  {
    id: 6,
    value: '$200k and up',
  },
];
