const STATES = [
  {
    0: { i: 5558, t: 21.91 },
    1: { i: 17005, t: 121.59 },
    2: { i: 35932, t: 836.64 },
    3: { i: 61443, t: 1352.11 },
    4: { i: 86600, t: 1886.68 },
    5: { i: 133082, t: 2946.47 },
    6: { i: 456739, t: 23051.53 },
    id: 1,
    abbr: 'AL',
    name: 'Alabama',
    jobs: 2941,
  },
  {
    0: { i: 4836, t: 21.71 },
    1: { i: 17416, t: 149.53 },
    2: { i: 36564, t: 1010.25 },
    3: { i: 61691, t: 1516.26 },
    4: { i: 86753, t: 2173.97 },
    5: { i: 136162, t: 3828.77 },
    6: { i: 403033, t: 21739.97 },
    id: 2,
    abbr: 'AK',
    name: 'Alaska',
    jobs: 476,
  },
  {
    0: { i: 5467, t: 21.37 },
    1: { i: 17271, t: 131.31 },
    2: { i: 35942, t: 1060.05 },
    3: { i: 61480, t: 1354.29 },
    4: { i: 86607, t: 1873.99 },
    5: { i: 134197, t: 2906.66 },
    6: { i: 465853, t: 23870.98 },
    id: 4,
    abbr: 'AZ',
    name: 'Arizona',
    jobs: 4098,
  },
  {
    0: { i: 5474, t: 22.97 },
    1: { i: 17138, t: 126.22 },
    2: { i: 35926, t: 846.06 },
    3: { i: 61382, t: 1368.59 },
    4: { i: 86442, t: 1899.64 },
    5: { i: 132617, t: 2871.82 },
    6: { i: 535685, t: 28240.63 },
    id: 5,
    abbr: 'AR',
    name: 'Arkansas',
    jobs: 1800,
  },
  {
    0: { i: 5414, t: 18.54 },
    1: { i: 17208, t: 141.47 },
    2: { i: 36122, t: 1040.43 },
    3: { i: 61470, t: 1265.71 },
    4: { i: 86626, t: 1670.89 },
    5: { i: 137600, t: 2328.4 },
    6: { i: 552385, t: 23452.41 },
    id: 6,
    abbr: 'CA',
    name: 'California',
    jobs: 24701,
  },
  {
    0: { i: 5194, t: 20.41 },
    1: { i: 17331, t: 147.56 },
    2: { i: 36400, t: 819.55 },
    3: { i: 61540, t: 1425.52 },
    4: { i: 86683, t: 1954.29 },
    5: { i: 136168, t: 3023.65 },
    6: { i: 473014, t: 24713.48 },
    id: 8,
    abbr: 'CO',
    name: 'Colorado',
    jobs: 3936,
  },
  {
    0: { i: 5004, t: 19.08 },
    1: { i: 17096, t: 252.74 },
    2: { i: 36555, t: 810.15 },
    3: { i: 61689, t: 1367.85 },
    4: { i: 86747, t: 1788.08 },
    5: { i: 137411, t: 2149.07 },
    6: { i: 643603, t: 20282 },
    id: 9,
    abbr: 'CT',
    name: 'Connecticut',
    jobs: 2444,
  },
  {
    0: { i: 5290, t: 16.15 },
    1: { i: 17216, t: 134.5 },
    2: { i: 36207, t: 832.76 },
    3: { i: 61596, t: 1422.89 },
    4: { i: 86720, t: 1913.02 },
    5: { i: 134841, t: 2708 },
    6: { i: 433436, t: 20930.83 },
    id: 10,
    abbr: 'DE',
    name: 'Delaware',
    jobs: 666,
  },
  {
    0: { i: 5437, t: 21.09 },
    1: { i: 17184, t: 143.14 },
    2: { i: 37079, t: 911.88 },
    3: { i: 61373, t: 1392.21 },
    4: { i: 86514, t: 1990.16 },
    5: { i: 138029, t: 2681.25 },
    6: { i: 545253, t: 22948.49 },
    id: 11,
    abbr: 'DC',
    name: 'District of Columbia',
    jobs: 1154,
  },
  {
    0: { i: 5439, t: 20.12 },
    1: { i: 17047, t: 137.05 },
    2: { i: 35845, t: 801.65 },
    3: { i: 61341, t: 1354.57 },
    4: { i: 86548, t: 1951.38 },
    5: { i: 134709, t: 3399.57 },
    6: { i: 652751, t: 31343.1 },
    id: 12,
    abbr: 'FL',
    name: 'Florida',
    jobs: 12642,
  },
  {
    0: { i: 5495, t: 21.14 },
    1: { i: 16958, t: 124.53 },
    2: { i: 35881, t: 1062.77 },
    3: { i: 61409, t: 1262.17 },
    4: { i: 86600, t: 1720.73 },
    5: { i: 135382, t: 2619.91 },
    6: { i: 479824, t: 22149.55 },
    id: 13,
    abbr: 'GA',
    name: 'Georgia',
    jobs: 6526,
  },
  {
    0: { i: 4972, t: 17.41 },
    1: { i: 17517, t: 241.31 },
    2: { i: 36408, t: 798.79 },
    3: { i: 61394, t: 1345.29 },
    4: { i: 86640, t: 1805.02 },
    5: { i: 134138, t: 2813.83 },
    6: { i: 425107, t: 21124.85 },
    id: 15,
    abbr: 'HI',
    name: 'Hawaii',
    jobs: 959,
  },
  {
    0: { i: 5262, t: 20.28 },
    1: { i: 17259, t: 262.24 },
    2: { i: 36112, t: 840.87 },
    3: { i: 61548, t: 1318.93 },
    4: { i: 86421, t: 1767.34 },
    5: { i: 132039, t: 2842.89 },
    6: { i: 479428, t: 25855.67 },
    id: 16,
    abbr: 'ID',
    name: 'Idaho',
    jobs: 1063,
  },
  {
    0: { i: 5163, t: 18.89 },
    1: { i: 17092, t: 138.17 },
    2: { i: 36331, t: 1038.69 },
    3: { i: 61598, t: 1385.98 },
    4: { i: 86678, t: 1892.19 },
    5: { i: 135613, t: 2706.05 },
    6: { i: 531995, t: 24693.7 },
    id: 17,
    abbr: 'IL',
    name: 'Illinois',
    jobs: 8830,
  },
  {
    0: { i: 5086, t: 18.18 },
    1: { i: 17068, t: 262.5 },
    2: { i: 36202, t: 831.86 },
    3: { i: 61607, t: 1438.51 },
    4: { i: 86567, t: 2004.27 },
    5: { i: 132016, t: 3216.81 },
    6: { i: 465643, t: 22251.91 },
    id: 18,
    abbr: 'IN',
    name: 'Indiana',
    jobs: 4529,
  },
  {
    0: { i: 5314, t: 17.12 },
    1: { i: 17285, t: 248.5 },
    2: { i: 36477, t: 817.57 },
    3: { i: 61672, t: 1423.99 },
    4: { i: 86693, t: 1943.54 },
    5: { i: 131989, t: 2949.52 },
    6: { i: 434859, t: 21677.8 },
    id: 19,
    abbr: 'IA',
    name: 'Iowa',
    jobs: 2293,
  },
  {
    0: { i: 5063, t: 19.39 },
    1: { i: 17238, t: 253.06 },
    2: { i: 36251, t: 820.98 },
    3: { i: 61627, t: 1410.71 },
    4: { i: 86696, t: 1976.27 },
    5: { i: 133577, t: 3165.62 },
    6: { i: 486998, t: 24965.53 },
    id: 20,
    abbr: 'KS',
    name: 'Kansas',
    jobs: 2052,
  },
  {
    0: { i: 5329, t: 21.91 },
    1: { i: 17084, t: 273.44 },
    2: { i: 36060, t: 837.83 },
    3: { i: 61475, t: 1401.11 },
    4: { i: 86529, t: 1898.44 },
    5: { i: 132226, t: 2760.45 },
    6: { i: 463155, t: 22830.29 },
    id: 21,
    abbr: 'KY',
    name: 'Kentucky',
    jobs: 2793,
  },
  {
    0: { i: 5579, t: 21.53 },
    1: { i: 16928, t: 120.6 },
    2: { i: 36087, t: 818.57 },
    3: { i: 61384, t: 1335.76 },
    4: { i: 86630, t: 1915.45 },
    5: { i: 134101, t: 3206.88 },
    6: { i: 465596, t: 24169.58 },
    id: 22,
    abbr: 'LA',
    name: 'Louisiana',
    jobs: 2879,
  },
  {
    0: { i: 5127, t: 18.24 },
    1: { i: 17340, t: 256.9 },
    2: { i: 36076, t: 822.7 },
    3: { i: 61517, t: 1423.27 },
    4: { i: 86526, t: 1929.1 },
    5: { i: 132191, t: 2748.5 },
    6: { i: 408375, t: 20508.13 },
    id: 23,
    abbr: 'ME',
    name: 'Maine',
    jobs: 911,
  },
  {
    0: { i: 4883, t: 15.68 },
    1: { i: 17104, t: 139.87 },
    2: { i: 36567, t: 977.28 },
    3: { i: 61614, t: 1194.98 },
    4: { i: 86803, t: 1545.75 },
    5: { i: 137534, t: 1827.78 },
    6: { i: 425117, t: 19995.67 },
    id: 24,
    abbr: 'MD',
    name: 'Maryland',
    jobs: 3952,
  },
  {
    0: { i: 4994, t: 16.42 },
    1: { i: 17171, t: 244.05 },
    2: { i: 36663, t: 802.53 },
    3: { i: 61593, t: 1437.06 },
    4: { i: 86729, t: 1921.66 },
    5: { i: 137816, t: 2613.27 },
    6: { i: 550485, t: 24146.18 },
    id: 25,
    abbr: 'MA',
    name: 'Massachusetts',
    jobs: 5279,
  },
  {
    0: { i: 4878, t: 16.37 },
    1: { i: 16925, t: 263.13 },
    2: { i: 36195, t: 822.04 },
    3: { i: 61641, t: 1437.19 },
    4: { i: 86665, t: 1991.47 },
    5: { i: 133575, t: 3041.67 },
    6: { i: 481216, t: 22527.17 },
    id: 26,
    abbr: 'MI',
    name: 'Michigan',
    jobs: 6396,
  },
  {
    0: { i: 4968, t: 14.86 },
    1: { i: 17269, t: 242.66 },
    2: { i: 36530, t: 812.25 },
    3: { i: 61685, t: 1429.18 },
    4: { i: 86786, t: 1893.27 },
    5: { i: 134404, t: 2612.96 },
    6: { i: 472841, t: 21862.15 },
    id: 27,
    abbr: 'MN',
    name: 'Minnesota',
    jobs: 4271,
  },
  {
    0: { i: 5697, t: 22.85 },
    1: { i: 16921, t: 117.13 },
    2: { i: 35601, t: 828.67 },
    3: { i: 61374, t: 1279.01 },
    4: { i: 86514, t: 1790.24 },
    5: { i: 132214, t: 2921.62 },
    6: { i: 431592, t: 23053.6 },
    id: 28,
    abbr: 'MS',
    name: 'Mississippi',
    jobs: 1683,
  },
  {
    0: { i: 5060, t: 19.55 },
    1: { i: 17145, t: 266.03 },
    2: { i: 36121, t: 827.19 },
    3: { i: 61527, t: 1406.08 },
    4: { i: 86539, t: 1937.25 },
    5: { i: 133148, t: 2917.4 },
    6: { i: 498985, t: 24509.44 },
    id: 29,
    abbr: 'MO',
    name: 'Missouri',
    jobs: 4184,
  },
  {
    0: { i: 5115, t: 22.24 },
    1: { i: 17256, t: 255.01 },
    2: { i: 36045, t: 827.15 },
    3: { i: 61571, t: 1410.93 },
    4: { i: 86675, t: 1949.48 },
    5: { i: 132021, t: 3151.3 },
    6: { i: 476344, t: 25910.35 },
    id: 30,
    abbr: 'MT',
    name: 'Montana',
    jobs: 687,
  },
  {
    0: { i: 5199, t: 18.18 },
    1: { i: 17317, t: 245.96 },
    2: { i: 36260, t: 816.92 },
    3: { i: 61634, t: 1405.21 },
    4: { i: 86711, t: 1927.24 },
    5: { i: 132934, t: 2903.99 },
    6: { i: 471734, t: 24075.81 },
    id: 31,
    abbr: 'NE',
    name: 'Nebraska',
    jobs: 1489,
  },
  {
    0: { i: 5439, t: 18.16 },
    1: { i: 17396, t: 130.58 },
    2: { i: 35900, t: 1031.47 },
    3: { i: 61516, t: 1341.35 },
    4: { i: 86440, t: 1876.54 },
    5: { i: 133547, t: 3137.54 },
    6: { i: 650928, t: 35761.23 },
    id: 32,
    abbr: 'NV',
    name: 'Nevada',
    jobs: 1986,
  },
  {
    0: { i: 4969, t: 15.01 },
    1: { i: 17332, t: 233.75 },
    2: { i: 36489, t: 794.61 },
    3: { i: 61634, t: 1435.68 },
    4: { i: 86771, t: 1975.69 },
    5: { i: 135545, t: 3156.2 },
    6: { i: 446868, t: 21801.79 },
    id: 33,
    abbr: 'NH',
    name: 'New Hampshire',
    jobs: 991,
  },
  {
    0: { i: 5046, t: 18.87 },
    1: { i: 17085, t: 137.33 },
    2: { i: 36450, t: 795.93 },
    3: { i: 61644, t: 1304.97 },
    4: { i: 86784, t: 1709.83 },
    5: { i: 138248, t: 1954.73 },
    6: { i: 486875, t: 19943.51 },
    id: 34,
    abbr: 'NJ',
    name: 'New Jersey',
    jobs: 6041,
  },
  {
    0: { i: 5089, t: 20.74 },
    1: { i: 17188, t: 128.71 },
    2: { i: 35874, t: 842.56 },
    3: { i: 61425, t: 1391.18 },
    4: { i: 86539, t: 1945.92 },
    5: { i: 133673, t: 3032.75 },
    6: { i: 414676, t: 21670.91 },
    id: 35,
    abbr: 'NM',
    name: 'New Mexico',
    jobs: 1217,
  },
  {
    0: { i: 5240, t: 19.78 },
    1: { i: 16954, t: 144.36 },
    2: { i: 36357, t: 818.63 },
    3: { i: 61478, t: 1386.15 },
    4: { i: 86625, t: 1868.7 },
    5: { i: 136237, t: 2090.17 },
    6: { i: 695374, t: 19008.29 },
    id: 36,
    abbr: 'NY',
    name: 'New York',
    jobs: 13897,
  },
  {
    0: { i: 5359, t: 22 },
    1: { i: 17128, t: 128.79 },
    2: { i: 35947, t: 833.3 },
    3: { i: 61396, t: 1328.02 },
    4: { i: 86587, t: 1792.98 },
    5: { i: 134322, t: 2667.12 },
    6: { i: 467109, t: 21911.27 },
    id: 37,
    abbr: 'NC',
    name: 'North Carolina',
    jobs: 6507,
  },
  {
    0: { i: 5271, t: 15.41 },
    1: { i: 17399, t: 226.62 },
    2: { i: 36480, t: 786.88 },
    3: { i: 61642, t: 1488.95 },
    4: { i: 86830, t: 2154.15 },
    5: { i: 132758, t: 3983.31 },
    6: { i: 479041, t: 25811.85 },
    id: 38,
    abbr: 'ND',
    name: 'North Dakota',
    jobs: 622,
  },
  {
    0: { i: 5186, t: 17.49 },
    1: { i: 17148, t: 260.09 },
    2: { i: 36400, t: 834.35 },
    3: { i: 61374, t: 1476.26 },
    4: { i: 86544, t: 2006.09 },
    5: { i: 132140, t: 2980.76 },
    6: { i: 468897, t: 21650.14 },
    id: 39,
    abbr: 'OH',
    name: 'Ohio',
    jobs: 8092,
  },
  {
    0: { i: 5304, t: 21.35 },
    1: { i: 17235, t: 129.12 },
    2: { i: 36080, t: 830.34 },
    3: { i: 61493, t: 1356.03 },
    4: { i: 86513, t: 1873.03 },
    5: { i: 133193, t: 2939.27 },
    6: { i: 503967, t: 28388.6 },
    id: 40,
    abbr: 'OK',
    name: 'Oklahoma',
    jobs: 2433,
  },
  {
    0: { i: 5260, t: 19.02 },
    1: { i: 17319, t: 250.58 },
    2: { i: 36172, t: 806.53 },
    3: { i: 61605, t: 1308.5 },
    4: { i: 86638, t: 1702.03 },
    5: { i: 134204, t: 2131.95 },
    6: { i: 447833, t: 22643.57 },
    id: 41,
    abbr: 'OR',
    name: 'Oregon',
    jobs: 2763,
  },
  {
    0: { i: 4888, t: 18.47 },
    1: { i: 17121, t: 257.93 },
    2: { i: 36367, t: 817.11 },
    3: { i: 61594, t: 1426.89 },
    4: { i: 86622, t: 1952.91 },
    5: { i: 134295, t: 2885.86 },
    6: { i: 492283, t: 23262.8 },
    id: 42,
    abbr: 'PA',
    name: 'Pennsylvania',
    jobs: 8693,
  },
  {
    0: { i: 5135, t: 15.46 },
    1: { i: 17109, t: 248.75 },
    2: { i: 36236, t: 803.2 },
    3: { i: 61720, t: 1402.18 },
    4: { i: 86644, t: 1860.18 },
    5: { i: 134985, t: 2534.4 },
    6: { i: 454546, t: 21711.95 },
    id: 44,
    abbr: 'RI',
    name: 'Rhode Island',
    jobs: 723,
  },
  {
    0: { i: 5371, t: 19.88 },
    1: { i: 17126, t: 126.61 },
    2: { i: 35830, t: 837.07 },
    3: { i: 61398, t: 1341.03 },
    4: { i: 86650, t: 1806.18 },
    5: { i: 133317, t: 2645.75 },
    6: { i: 447935, t: 22586.14 },
    id: 45,
    abbr: 'SC',
    name: 'South Carolina',
    jobs: 3068,
  },
  {
    0: { i: 5067, t: 17.04 },
    1: { i: 17369, t: 245.71 },
    2: { i: 36301, t: 815.35 },
    3: { i: 61692, t: 1455.69 },
    4: { i: 86673, t: 2114.22 },
    5: { i: 132010, t: 4007.4 },
    6: { i: 535553, t: 31924.65 },
    id: 46,
    abbr: 'SD',
    name: 'South Dakota',
    jobs: 637,
  },
  {
    0: { i: 5392, t: 20.03 },
    1: { i: 17067, t: 130.38 },
    2: { i: 35977, t: 829.85 },
    3: { i: 61393, t: 1392.05 },
    4: { i: 86508, t: 1989.34 },
    5: { i: 133275, t: 3452.7 },
    6: { i: 509883, t: 25700.2 },
    id: 47,
    abbr: 'TN',
    name: 'Tennessee',
    jobs: 4419,
  },
  {
    0: { i: 5319, t: 18.61 },
    1: { i: 17128, t: 130.45 },
    2: { i: 36158, t: 1068.82 },
    3: { i: 61301, t: 1324.56 },
    4: { i: 86675, t: 1905.39 },
    5: { i: 135778, t: 3233.06 },
    6: { i: 531977, t: 27629.72 },
    id: 48,
    abbr: 'TX',
    name: 'Texas',
    jobs: 18128,
  },
  {
    0: { i: 5165, t: 14.69 },
    1: { i: 17319, t: 130.94 },
    2: { i: 36344, t: 1088.42 },
    3: { i: 61700, t: 1478.97 },
    4: { i: 86593, t: 1796.12 },
    5: { i: 132492, t: 2558.96 },
    6: { i: 499862, t: 25196.99 },
    id: 49,
    abbr: 'UT',
    name: 'Utah',
    jobs: 2187,
  },
  {
    0: { i: 4914, t: 21.38 },
    1: { i: 17362, t: 255.43 },
    2: { i: 36246, t: 813.77 },
    3: { i: 61465, t: 1472.48 },
    4: { i: 86664, t: 2003.62 },
    5: { i: 132594, t: 2867.48 },
    6: { i: 438950, t: 21724.44 },
    id: 50,
    abbr: 'VT',
    name: 'Vermont',
    jobs: 455,
  },
  {
    0: { i: 5209, t: 18.3 },
    1: { i: 17200, t: 136.01 },
    2: { i: 36394, t: 799.82 },
    3: { i: 61619, t: 1291.8 },
    4: { i: 86810, t: 1733.39 },
    5: { i: 137346, t: 2514.23 },
    6: { i: 422103, t: 20246.73 },
    id: 51,
    abbr: 'VA',
    name: 'Virginia',
    jobs: 5782,
  },
  {
    0: { i: 5178, t: 18.19 },
    1: { i: 17421, t: 244.25 },
    2: { i: 36460, t: 806.62 },
    3: { i: 61634, t: 1386.88 },
    4: { i: 86736, t: 1927.18 },
    5: { i: 135805, t: 3123.27 },
    6: { i: 494868, t: 27924.61 },
    id: 53,
    abbr: 'WA',
    name: 'Washington',
    jobs: 4931,
  },
  {
    0: { i: 5194, t: 22.1 },
    1: { i: 17075, t: 266.72 },
    2: { i: 36112, t: 823.11 },
    3: { i: 61662, t: 1428.26 },
    4: { i: 86506, t: 2018.62 },
    5: { i: 130987, t: 3252.71 },
    6: { i: 414028, t: 21382.71 },
    id: 54,
    abbr: 'WV',
    name: 'West Virginia',
    jobs: 1099,
  },
  {
    0: { i: 5007, t: 18.21 },
    1: { i: 17189, t: 247.38 },
    2: { i: 36389, t: 809.71 },
    3: { i: 61679, t: 1411.88 },
    4: { i: 86693, t: 1884.99 },
    5: { i: 131887, t: 2692.34 },
    6: { i: 492206, t: 22968.77 },
    id: 55,
    abbr: 'WI',
    name: 'Wisconsin',
    jobs: 4284,
  },
  {
    0: { i: 5133, t: 21.11 },
    1: { i: 17266, t: 243.22 },
    2: { i: 36538, t: 816.31 },
    3: { i: 61872, t: 1439.44 },
    4: { i: 86816, t: 2052.34 },
    5: { i: 131946, t: 3693.65 },
    6: { i: 735029, t: 38366.07 },
    id: 56,
    abbr: 'WY',
    name: 'Wyoming',
    jobs: 413,
  },
];

export default STATES;
